import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import { PassportBadge } from '../../Components/Passport/PassportBadge'
import { RewardPage } from '../../Components/Passport/RewardPage'
import { PassportPages, switchPassportPage, updatePassportBadges } from './PassportBadgesSlice'
import { Icon, MenuDrawer, MenuHeader, ShadowedButton } from '../../Common/common-index'
import { Badge, eventService } from '../../Services/services-index'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: '32px',
  },
  paperContainer: {
    height: '100%',
    backgroundColor: theme.palette.background.default,
  },
  coninsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0, 4),
    justifyContent: 'space-between',
  },
  redeemButton: {
    marginTop: theme.spacing(7),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '400px',
    },
  },
  coninsIconWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  coinsCount: {
    marginRight: '10px',
  },
  title: {
    fontSize: '15px',
    fontFamily: 'Graphik-Semibold',
    lineHeight: '30px',
  },
  description: {
    fontSize: '15px',
    fontFamily: 'Graphik-Regular',
    lineHeight: '22px',
  },
  achievementList: {
    margin: theme.spacing(5, 0, 3, 0),
    padding: theme.spacing(0, 4),
  },
  rewardScreen: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(8, 4),
    backgroundColor: theme.palette.background.default,
  },
  noBadge: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Graphik',
    fontWeight: 'bold',
    position: 'absolute',
    top: '50%',
    left: '32px',
  },
}))

export const PassportContainer: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const activePassportBadges = useAppSelector((state) => state.passport.passportBadges)
  const preferences = useAppSelector((state) => state.preferences.specificPreferences)

  const availableBadgeList = activePassportBadges && activePassportBadges.badges

  const [achievementsHasCoins, setAchievementsHasCoins] = useState<boolean>(false)
  const [isPassportIsReadyToClaim, setIsPassportIsReadyToClaim] = useState<boolean>(false)
  const [achievementStorePreference, setAchievementStorePreference] = useState<any>()

  useEffect(() => {
    dispatch(updateIsSideMenuOpen(true))
    if (Object.keys(activePassportBadges).length === 0) {
      eventService
        .getPassport()
        .then((res: any) => {
          if (res) {
            dispatch(updatePassportBadges(res.data))
          }
        })
        .catch(() => {
          console.error('An unexpected error occurred. Please try again later.')
        })
    }
    claimPassportBadgeCheck()
  }, [activePassportBadges, isPassportIsReadyToClaim])

  useEffect(() => {
    if (availableBadgeList) {
      const conisCheck = availableBadgeList.filter((badge: Badge) => {
        return badge?.coin_value !== null && badge?.coin_value !== undefined
      })
      conisCheck?.length > 0 ? setAchievementsHasCoins(true) : setAchievementsHasCoins(false)
    }
  }, [availableBadgeList])

  useEffect(() => {}, [achievementsHasCoins])

  useEffect(() => {
    if (preferences) {
      setAchievementStorePreference(preferences['achievement_store'])
    }
  }, [preferences])

  // Render available passport badge list
  const renderPassportBadges = () => {
    if (!activePassportBadges || availableBadgeList?.length === 0) {
      return null
    }

    if (availableBadgeList) {
      return availableBadgeList.map((badge: Badge) => {
        return (
          <PassportBadge
            passportBadge={badge}
            key={badge.id}
            achievementStorePreference={achievementStorePreference}
          />
        )
      })
    }
    return
  }

  // Click on Claim Your Reward
  const claimPassportBadges = () => {
    eventService.claimPassport()
    dispatch(switchPassportPage(PassportPages.PassportClaimedPage))
  }

  // Check isPassportIsReadyToClaim
  const claimPassportBadgeCheck = () => {
    const claimedBadges =
      availableBadgeList &&
      availableBadgeList.filter((badge: Badge) => badge.badge_complete === true)
    const claimedBadgesCount = claimedBadges && claimedBadges.length
    const availableBadgesCount = availableBadgeList && availableBadgeList.length
    availableBadgesCount === claimedBadgesCount && achievementStorePreference?.value === 'no'
      ? setIsPassportIsReadyToClaim(true)
      : setIsPassportIsReadyToClaim(false)
    if (activePassportBadges?.passport_claimed) {
      dispatch(switchPassportPage(PassportPages.PassportClaimedPage))
    }
  }

  const onRedeemCoinsClick = () => {
    dispatch(switchPassportPage(PassportPages.RedeemCoinsPage))
  }

  // User Actions
  const OnCloseClick = () => {
    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchMenuPage(MenuPages.Menu))
  }

  const OnGoBack = () => {
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(toggleMenu(true))
  }

  return (
    <MenuDrawer
      isOpen={true}
      drawerHeader={
        <MenuHeader
          title={t('passport.achievementsList')}
          onGoBack={OnGoBack}
          onClose={OnCloseClick}
        />
      }
    >
      <div className={classes.paperContainer}>
        <div className={classes.rewardScreen}>
          <RewardPage
            isPassportIsReadyToClaim={isPassportIsReadyToClaim}
            onClaimBadgeClick={claimPassportBadges}
          ></RewardPage>
          {achievementStorePreference && achievementStorePreference.value === 'yes' && (
            <ShadowedButton
              id='passport-redeem-coins-button'
              text={t('passport.redeemCoins.title')}
              buttonStyle={'default'}
              wrapperClasses={classes.redeemButton}
              onClick={onRedeemCoinsClick}
            ></ShadowedButton>
          )}
        </div>
        {achievementsHasCoins && achievementStorePreference?.value === 'yes' && (
          <div className={classes.coninsWrapper}>
            <Typography variant={'h6'} className={classes.title}>
              {t('passport.redeemCoins.myCoins')}
            </Typography>
            <div className={classes.coninsIconWrapper}>
              <Typography variant={'h6'} className={`${classes.description} ${classes.coinsCount}`}>
                {activePassportBadges?.coin_balance}
              </Typography>
              <Icon iconName={'coin'} iconSize={'36px'} />
            </div>
          </div>
        )}
        <div className={classes.achievementList}>
          <Typography variant={'h6'} className={classes.title}>
            {t('passport.redeemCoins.achievementList')}
          </Typography>
        </div>

        {!activePassportBadges?.passport_claimed && (
          <div className={classes.container}>
            <Grid className={classes.container} container>
              <Grid item xs={12}>
                {renderPassportBadges()}
              </Grid>
            </Grid>
          </div>
        )}
        {!activePassportBadges?.badges?.length && (
          <div className={classes.container}>
            <Typography variant={'h5'} className={classes.noBadge}>
              {t('passport.emptyBadgePage')}
            </Typography>
          </div>
        )}
      </div>
    </MenuDrawer>
  )
}

export default PassportContainer
