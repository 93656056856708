import React, { FC, useEffect } from 'react'
import { MenuDrawer, MenuHeader } from '../../Common/common-index'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { useTranslation } from 'react-i18next'
import { userService } from '../../Services/services-index'
import { Token, updateTokens } from './tokenWalletSlice'
import TokenComponent from '../../Common/Components/TokenComponent/TokenComponent'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  paperContainer: {
    height: '100%',
    backgroundColor: theme.palette.background.default,
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(8, 4),
    backgroundColor: theme.palette.background.default,
  },
  pageTitle: {
    fontSize: '15px',
    fontFamily: 'Graphik',
    fontWeight: 'bold',
    lineHeight: '30px',
  },
  pageDescription: {
    fontSize: '15px',
    fontFamily: 'Graphik',
    lineHeight: '25px',
  },
}))

const TokenListContainer: FC = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const userWallet = useAppSelector((state) => state.tokenWallet.tokens)

  useEffect(() => {
    userService
      .getUserWallet()
      .then((res: any) => {
        if (res) {
          dispatch(updateTokens(res.data))
          console.log(res)
        }
      })
      .catch(() => {
        console.error('An unexpected error occurred. Please try again later.')
      })
  }, [])

  // User Actions
  const OnCloseClick = () => {
    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchMenuPage(MenuPages.Menu))
  }

  const OnGoBack = () => {
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(toggleMenu(true))
  }

  const renderTokens = () => {
    if (userWallet?.length === 0) {
      return null
    } else {
      return userWallet.map((token: Token, key: any) => {
        return <TokenComponent token={token} key={key} />
      })
    }
  }

  return (
    <MenuDrawer
      isOpen={true}
      drawerHeader={
        <MenuHeader
          title={t('tokenWalletPage.tokenWalletContainer.header')}
          onGoBack={OnGoBack}
          onClose={OnCloseClick}
        />
      }
    >
      <div className={classes.paperContainer}>
        <div className={classes.container}>
          <div className={classes.headerWrapper}>
            <Typography variant={'h6'} className={classes.pageTitle}>
              {t('tokenWalletPage.tokenWalletContainer.pageTitle')}
            </Typography>
            <Typography variant={'body1'} className={classes.pageDescription}>
              {t('tokenWalletPage.tokenWalletContainer.description')}
            </Typography>
          </div>
        </div>
        <div className={classes.container}>
          <Grid className={classes.container} container>
            <Grid item xs={12}>
              {renderTokens()}
            </Grid>
          </Grid>
        </div>
      </div>
    </MenuDrawer>
  )
}

export default TokenListContainer
