import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { FC, useState } from 'react'
import parse from 'html-react-parser'
import DOMPurify from 'dompurify'

export interface FaqModel {
  id: any
  event_id: number
  category: string
  question: string
  answer: string
  answer_type?: string
  faq_order?: any
  frontend: string
}

interface Params {
  faqItem?: FaqModel
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexDirection: 'column',
    height: '100%',
    outline: 'none',
    padding: theme.spacing(1, 4),
  },
  faqContainer: {
    boxShadow: '0px 0px 10px #0000002E',
    cursor: 'pointer',
    display: 'flex',
    opacity: '1',
    outline: 'none',
    padding: theme.spacing(1, 3),
  },
  faqItemInformation: {
    display: 'flex',
    flexDirection: 'column',
  },
  faqItemWrapper: {
    fontFamily: 'Graphik',
    fontSize: '16px',
    fontWeight: 'bold',
    padding: theme.spacing(1, 0, 2, 0),
  },
  faqItemDescription: {
    color: 'black',
    cursor: 'default',
    fontFamily: 'Graphik',
    fontSize: '16px',
    lineHeight: '22px',
    padding: theme.spacing(0, 3, 3, 0),
  },
}))

export const FaqItemComponent: FC<Params> = ({ faqItem }) => {
  const classes = useStyles()

  const [someMap, setSomeMap] = useState(new Map())

  // On FAQ item click
  const onItemClick = (itemId: string) => {
    if (someMap.get(itemId)) {
      setSomeMap(new Map(someMap.set(itemId, false)))
    } else {
      setSomeMap(new Map(someMap.set(itemId, true)))
    }
  }

  if (!faqItem) {
    return null
  }

  const sanitizedHtml = DOMPurify.sanitize(faqItem.answer)
  const content = parse(sanitizedHtml)

  return (
    <>
      {faqItem && (
        <div className={classes.root}>
          <div className={classes.faqContainer} onClick={() => onItemClick(faqItem.id)}>
            <div className={classes.faqItemInformation}>
              <Typography variant={'h2'} className={classes.faqItemWrapper}>
                {faqItem.question}
              </Typography>
              {someMap.get(faqItem.id) && (
                <Typography variant={'body1'} className={classes.faqItemDescription}>
                  {content}
                </Typography>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default FaqItemComponent
