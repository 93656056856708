import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import { Divider, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import { conversationsService, userService } from '../../Services/services-index'
import {
  ChatPages,
  switchChatPage,
  updateActiveConversation,
  updateActiveConversationId,
  updateAddNewUsersIsActive,
  updateConversations,
} from './ChatSlice'
import UserAvatar from '../UserAvatar/UserAvatar'
import ChatToolbarContainer from './ChatToolbarContainer'
import ChatMessageInputContainer from './ChatMessageInputContainer'
import {
  BusinessCardPages,
  switchBusinessCardPagePage,
  updateIsChatMenuBusinessCardView,
  updateRequestBusinessCardFromUserId,
  updateIsBusinessCardApproved,
  updateIsBusinessCardRequestApproved,
} from '../BusinessCards/businessCardsSlice'
import { updateIsRoomConversation } from '../RoomChat/RoomChatSlice'
import {
  businessCardService,
  Conversation,
  MessageActions,
  MessageTypes,
  User,
} from '../../Services/services-index'
import {
  ChatMessage,
  ChatTimestamp,
  ConfirmationDialog,
  MenuDrawer,
  MenuHeader,
} from '../../Common/common-index'
import {
  RecruitingDashboardPages,
  switchRecruitingDashboardPage,
  updateHasNotificationPageOpen,
  updateIsRecruitingMobileViewActive,
} from '../RecruitingDashboard/RecruitingDashboardSlice'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperContainer: {
      '& div[class*="header"]': { minHeight: '80px' },
    },
    messagesWrapper: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 20px',
      backgroundColor: theme.palette.background.default,
      minHeight: '100%',
      flexWrap: 'nowrap',
    },
    avatar: {
      border: '3px solid white',
      width: 50,
      height: 50,
    },
    chatToolWrapper: {
      '& div[class*="MuiGrid-container"]': {
        display: 'flex',
        justifyContent: 'space-evenly',
      },
    },
  })
)

export interface Props {
  isRecruitingDashboardChat?: boolean
}

const ChatMessagesContainer: FC<Props> = ({ isRecruitingDashboardChat }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useAppDispatch()

  const activeConversationId = useAppSelector((state) => state.chat.activeConversationId)
  const allConversations = useAppSelector((state) => state.chat.conversations)
  const currentUser = useAppSelector((state) => state.auth.currentUser)
  const isBusinessCardApproved = useAppSelector(
    (state) => state.businessCards.isBusinessCardApproved
  )
  const isRecruitingMobileViewActive = useAppSelector(
    (state) => state.recruiting.isRecruitingMobileViewActive
  )

  const preferences = useAppSelector((state) => state.preferences.selectedPreferences)
  const currentConversation = useAppSelector((state) => state.chat.activeConversation)
  const isUserListPageActive = useAppSelector((state) => state.userList.isUserListPageActive)

  const [title, setTitle] = useState('')
  const [otherUser, setOtherUserId] = useState<number | undefined>()

  const [isGroup, setIsGroup] = useState(false)
  const [isBotConversation, setIsBotConversation] = useState(false)
  const [isAllowDisabled, setIsAllowDisabled] = useState(false)
  const [isBusinessCardAllowed, setIsBusinessCardAllowed] = useState(false)
  const [isDenied, setIsDenied] = useState(false)

  const [participantsCount, setParticipantsCount] = useState(0)
  const [conversation, setConversation] = useState<Conversation>({} as Conversation)
  const [displayMessages, setDisplayMessages] = useState<any>()
  const [privateChatPreference, setPrivateChatPreference] = useState<any>()
  const [enableAutoScroll, setEnableAutoScroll] = useState<boolean>(true)
  const [hasNewMessageArrived, setHasNewMessageArrived] = useState<boolean>(false)
  const [bottomButtonTitle, setBottomButtonTitle] = useState<string>('')
  const [hasScrolledUp, setHasScrolledUp] = useState<boolean>(false)
  const [hasScrolledToTheTop, setHasScrolledToTheTop] = useState<boolean>(false)
  const [chatWindow, setChatWindow] = useState<any>()
  const [reportUserDialogOpen, setReportUserDialogOpen] = useState<boolean>(false)
  const [disableAction, setDisableAction] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<any>()

  const isNewDay = (previousMessageTime: string, messageTime: string) => {
    if (!previousMessageTime) {
      return true
    }

    const convertedMessageTime = new Date(parseInt(messageTime, 10))
    const convertedPreviousMessageTime = new Date(parseInt(previousMessageTime, 10))
    const isSameDay =
      convertedMessageTime.getFullYear() === convertedPreviousMessageTime.getFullYear() &&
      convertedMessageTime.getMonth() === convertedPreviousMessageTime.getMonth() &&
      convertedMessageTime.getDay() === convertedPreviousMessageTime.getDay()
    return !isSameDay
  }

  useEffect(() => {
    if (preferences) {
      setPrivateChatPreference(preferences['private_chat'])
    }
  }, [preferences, privateChatPreference])

  useEffect(() => {
    if (allConversations && currentUser) {
      dispatch(updateIsRoomConversation(false))
      const activeConversation = allConversations[activeConversationId]
      if (activeConversation) {
        setConversation(activeConversation)
        dispatch(updateActiveConversation(activeConversation))
        const { participants, isRoomConversation, isUnread, bot } = activeConversation
        if (isUnread) {
          if (!hasScrolledUp) {
            const update: Conversation = {
              ...activeConversation,
              isUnread: false,
            }
            dispatch(updateConversations({ [activeConversationId]: update }))
          } else {
            setHasNewMessageArrived(true)
          }
        }
        if (participants) {
          setParticipantsCount(participants.length)
          if (participants?.length && !isRoomConversation) {
            const otherUser = participants.filter((p: User) => p.id !== currentUser?.id)[0]
            if (!otherUser) {
              console.error('no user found')
            } else {
              setTitle(otherUser.firstname + ' ' + otherUser.lastname)
              setOtherUserId(otherUser.id)
            }
          }
          if (participants?.length > 2) {
            setIsGroup(true)
            const otherUser = participants.filter((p: User) => p.id !== currentUser.id)[0]
            setTitle(
              otherUser.firstname + ' ' + otherUser.lastname + ' + ' + (participants.length - 1)
            )
          }

          // Check is bot conversation
          !!bot ? setIsBotConversation(true) : setIsBotConversation(false)
        }
      }
    }
  }, [activeConversationId, isGroup, allConversations])

  useEffect(() => {
    if (conversation) {
      setDisplayMessages(renderChatMessages())
    }
  }, [
    conversation,
    currentConversation,
    isBusinessCardAllowed,
    isBusinessCardApproved,
    isAllowDisabled,
    isDenied,
  ])

  useEffect(() => {
    dispatch(updateIsSideMenuOpen(true))
    if (enableAutoScroll) {
      scrollToBottom()
    }
  }, [conversation, displayMessages])

  useEffect(() => {
    dispatch(updateIsBusinessCardApproved(false))
  }, [otherUser])

  useEffect(() => {
    if (currentUser?.id && otherUser) {
      businessCardService.getBusinessCardInfo(currentUser.id, otherUser).then((res: any) => {
        if (res.data?.approved === true) {
          setIsAllowDisabled(false)
          setIsBusinessCardAllowed(true)
        } else if (res.data?.approved === false) {
          setIsAllowDisabled(true)
          setIsBusinessCardAllowed(false)
        }
      })
      businessCardService.getBusinessCardInfo(otherUser, currentUser.id).then((res: any) => {
        if (res.data?.approved === true) {
          setIsDenied(false)
          dispatch(updateIsBusinessCardApproved(true))
        } else if (res.data?.approved === false) {
          setIsDenied(true)
        }
      })
    }
  }, [conversation])

  useEffect(() => {
    if (otherUser) {
      dispatch(updateRequestBusinessCardFromUserId(otherUser))
    }
  }, [otherUser, isBusinessCardApproved])

  useEffect(() => {
    if (!enableAutoScroll) {
      !hasNewMessageArrived
        ? setBottomButtonTitle('Return to bottom')
        : setBottomButtonTitle('You have new messages')
    }
  }, [enableAutoScroll, bottomButtonTitle, hasNewMessageArrived])

  // Add event listener for scroll
  useEffect(() => {
    const chatWindow = document.getElementById('chat-input-container')
    chatWindow?.addEventListener('scroll', handleScroll, true)
    return () => {
      chatWindow?.removeEventListener('scroll', handleScroll)
    }
  }, [enableAutoScroll])

  useEffect(() => {
    if (!hasScrolledUp && conversation.isUnread) {
      const update: Conversation = {
        ...conversation,
        isUnread: false,
      }
      dispatch(updateConversations({ [activeConversationId]: update }))
    }
  }, [hasScrolledUp])

  useEffect(() => {
    if (hasScrolledToTheTop && currentConversation.page_state) {
      conversationsService
        .getConversationMessages(activeConversationId, undefined, currentConversation.page_state)
        .then((res: any) => {
          if (currentConversation.messages) {
            const { messages, page_state } = res.data
            const update = {
              ...currentConversation,
              messages: [...messages, ...currentConversation.messages],
              page_state: page_state,
            }
            dispatch(updateConversations({ [activeConversationId]: update }))
            chatWindow.scrollTop = 100
          }
        })
    }
  }, [hasScrolledToTheTop])

  const handleScroll = (e: any) => {
    const { clientHeight, scrollTop, scrollHeight } = e?.target
    setChatWindow(e?.target)
    if (scrollHeight - scrollTop <= clientHeight + 50) {
      setEnableAutoScroll(true)
      setHasNewMessageArrived(false)
      setHasScrolledUp(false)
    } else {
      scrollTop < 100 ? setHasScrolledToTheTop(true) : setHasScrolledToTheTop(false)
      setHasScrolledUp(true)
      setEnableAutoScroll(false)
    }
  }

  // Scroll to the bottom of user conversation messages
  const scrollToBottom = () => {
    const reference = window?.document?.getElementById('lastMessageRefId')
    if (reference) {
      reference.scrollIntoView({
        behavior: 'auto',
      })
    }
    setBottomButtonTitle('')
    setHasNewMessageArrived(false)
  }

  const getUserName = (from: number) => {
    const user = [...conversation.participants].filter((user) => user.id === from)
    return user[0] ? `${user[0].firstname} ${user[0].lastname} - ` : ' '
  }

  const allowBusinessCard = () => {
    if (currentUser?.id && otherUser) {
      businessCardService
        .getBusinessCardInfo(currentUser.id, otherUser)
        .then((res: any) => {
          if (res?.data?.card_id) {
            businessCardService
              .editBusinessCardNotes(res.data.card_id, 'Card allow to show', true)
              .then((res: any) => {
                if (res?.status === 200) {
                  setIsAllowDisabled(false)
                  setIsBusinessCardAllowed(true)
                  dispatch(updateIsBusinessCardRequestApproved(true))
                }
              })
          }
        })
        .catch(() => {
          console.error('An unexpected error occurred. Please try again later.')
        })
    }
  }

  const denyBusinessCard = () => {
    if (currentUser?.id && otherUser) {
      businessCardService
        .getBusinessCardInfo(currentUser.id, otherUser)
        .then((res: any) => {
          if (res?.data?.card_id) {
            businessCardService
              .editBusinessCardNotes(res.data.card_id, 'Card denied', false)
              .then((res: any) => {
                if (res?.status === 200) {
                  setIsAllowDisabled(true)
                  setIsBusinessCardAllowed(false)
                  dispatch(updateIsBusinessCardRequestApproved(false))
                }
              })
          }
        })
        .catch(() => {
          console.error('An unexpected error occurred. Please try again later.')
        })
    }
  }

  const renderChatMessages = () => {
    const currentUserId = currentUser?.id
    let isInitialMessage = true
    let previousMessageTime = ''
    const { messages } = conversation

    if (messages) {
      const visibleMessages = messages.filter(
        (m) =>
          m.type === MessageTypes.text &&
          m.action !== MessageActions.start &&
          m.action !== MessageActions.delete
      )

      const length = visibleMessages.length
      return visibleMessages.map((payload, index) => {
        const { message, from, timestamp } = payload
        const isReceived = currentUserId !== from

        if (index === length - 1 && enableAutoScroll) {
          scrollToBottom()
        }

        if (isNewDay(previousMessageTime, timestamp)) {
          const text = isInitialMessage
            ? t('chatPage.chatMessages.timestamp.conversationStart')
            : ''
          isInitialMessage = false
          previousMessageTime = timestamp
          return (
            <div key={payload.id}>
              <ChatTimestamp text={text} timestamp={new Date(parseInt(timestamp, 10))} />
              <Typography variant={'body2'}>
                {t('chatPage.chatMessages.timestamp.conversationExport')}
              </Typography>
              <ChatMessage
                key={payload.id}
                text={message as string}
                time={new Date(parseInt(timestamp, 10))}
                isReceived={isReceived}
                subtitle={isGroup ? getUserName(from) : ''}
                isGroupChat={isGroup}
                avatar={
                  <UserAvatar
                    userId={from as number}
                    hasUnreadMessage={false}
                    width={50}
                    height={50}
                    tooltipPlacement='bottom'
                  />
                }
                isBusinessCardMessage={payload?.cards}
                isAllowDisabled={isAllowDisabled}
                isBusinessCardApproved={isBusinessCardApproved}
                isBusinessCardAllowed={isBusinessCardAllowed}
                isDenied={isDenied}
                allowBusinessCard={allowBusinessCard}
                denyBusinessCard={denyBusinessCard}
              />
            </div>
          )
        } else {
          return (
            <ChatMessage
              key={payload.id}
              text={message as string}
              time={new Date(parseInt(timestamp, 10))}
              isReceived={isReceived}
              subtitle={isGroup ? getUserName(from) : ''}
              isGroupChat={isGroup}
              avatar={
                <UserAvatar
                  userId={from as number}
                  hasUnreadMessage={false}
                  width={50}
                  height={50}
                  tooltipPlacement='bottom'
                />
              }
              isBusinessCardMessage={payload?.cards}
              isAllowDisabled={isAllowDisabled}
              isBusinessCardApproved={isBusinessCardApproved}
              isBusinessCardAllowed={isBusinessCardAllowed}
              isDenied={isDenied}
              allowBusinessCard={allowBusinessCard}
              denyBusinessCard={denyBusinessCard}
            />
          )
        }
      })
    }

    return null
  }

  // Header Actions
  const OnCloseClick = () => {
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    reSetConversation()
    dispatch(updateIsChatMenuBusinessCardView(false))

    if (isRecruitingDashboardChat && isRecruitingMobileViewActive) {
      dispatch(switchRecruitingDashboardPage(RecruitingDashboardPages.RecruitingDashboardMainMenu))
      dispatch(updateIsRecruitingMobileViewActive(false))
    } else {
      dispatch(switchChatPage(ChatPages.ChatList))
    }
  }

  const OnGoBack = () => {
    if (isUserListPageActive && !(isRecruitingDashboardChat && isRecruitingMobileViewActive)) {
      reSetConversation()
      dispatch(switchChatPage(ChatPages.ChatList))
      dispatch(switchMenuPage(MenuPages.UserList))
    } else if (
      !isUserListPageActive &&
      !(isRecruitingDashboardChat && isRecruitingMobileViewActive)
    ) {
      reSetConversation()
      dispatch(updateIsChatMenuBusinessCardView(false))
      dispatch(switchChatPage(ChatPages.ChatList))
    }
    if (isRecruitingDashboardChat && isRecruitingMobileViewActive) {
      dispatch(
        switchRecruitingDashboardPage(RecruitingDashboardPages.RecruitingDashboardProfilePage)
      )
    }
  }

  // To reset conversation state
  const reSetConversation = () => {
    dispatch(updateActiveConversationId(''))
    dispatch(updateActiveConversation({} as Conversation))
  }

  // Footer Actions
  const onParticipantsClick = (event?: any) => {
    if (event) {
      dispatch(switchChatPage(ChatPages.ChatParticipantList))
    }
  }

  const onAddUserClick = (event?: any) => {
    if (event) {
      dispatch(updateAddNewUsersIsActive(true))
      dispatch(switchChatPage(ChatPages.ChatNewConversation))
    }
  }

  const onBusinessCardClick = (event?: any) => {
    if (isBotConversation || isGroup) {
      return
    }

    if (event) {
      event.stopPropagation()
      dispatch(updateIsChatMenuBusinessCardView(true))
      dispatch(switchMenuPage(MenuPages.BusinessCards))
      dispatch(switchBusinessCardPagePage(BusinessCardPages.RequestBusinessCardPage))
    }
  }

  const onNotificationClick = (event?: any) => {
    if (event) {
      dispatch(updateHasNotificationPageOpen(true))
    }
  }

  const onCancelReportDialog = () => {
    setErrorMessage('')
    setSuccessMessage('')
    setDisableAction(false)
    setReportUserDialogOpen(false)
  }

  const reportUser = () => {
    if (currentUser?.id && otherUser) {
      userService
        .reportUser(otherUser, currentUser?.id)
        .then((res) => {
          if (res?.status === 200) {
            setErrorMessage('')
            setDisableAction(true)
            setSuccessMessage(res?.data?.message)
            // Turn of success message
            setTimeout(() => {
              setSuccessMessage('')
              setDisableAction(false)
            }, 15000)
          }
        })
        .catch((error) => {
          const errorText = error?.response?.data?.message
          setErrorMessage(errorText)
          setSuccessMessage('')
          // Turn of error message
          setTimeout(() => {
            setErrorMessage('')
          }, 10000)
        })
    }
  }

  return (
    <div className={classes.paperContainer} id='chat-input-container'>
      <MenuDrawer
        isOpen={true}
        drawerHeader={
          <MenuHeader
            title={!isRecruitingDashboardChat ? title : 'Chat'}
            onGoBack={
              !isRecruitingDashboardChat ||
              (isRecruitingDashboardChat && isRecruitingMobileViewActive) ||
              (!isRecruitingDashboardChat &&
                privateChatPreference &&
                privateChatPreference.value === 'yes')
                ? OnGoBack
                : undefined
            }
            hasDivider={isRecruitingDashboardChat ? true : false}
            fullDivider={isRecruitingDashboardChat ? true : false}
            onClose={OnCloseClick}
            avatar={
              !isRecruitingDashboardChat ? (
                <UserAvatar
                  userId={otherUser as number}
                  hasUnreadMessage={false}
                  enableBorder
                  isGroup={currentConversation?.participants?.length > 2}
                  width={50}
                  height={50}
                  isNotClickable={!!(isGroup || isBotConversation)}
                  onClick={onBusinessCardClick}
                  tooltipPlacement='bottom'
                />
              ) : null
            }
          />
        }
        drawerFooter={
          <Grid container direction={'column'} spacing={1}>
            <Grid item xs={12}>
              <ChatMessageInputContainer
                enableAutoScroll={enableAutoScroll}
                bottomButtonTitle={bottomButtonTitle}
                hasNewMessageArrived={hasNewMessageArrived}
                onBottomReturn={() => scrollToBottom()}
              />
            </Grid>
            {!isBotConversation && (
              <Grid item xs={12} className={classes.chatToolWrapper}>
                <Divider />
                <ChatToolbarContainer
                  participantsCount={participantsCount}
                  toolbarType={
                    isRecruitingDashboardChat ? 'recruiting' : isGroup ? 'group' : 'individual'
                  }
                  onParticipantsClick={onParticipantsClick}
                  onAddUserClick={onAddUserClick}
                  onBusinessCardClick={onBusinessCardClick}
                  onNotificationClick={onNotificationClick}
                  onReportUserClick={() => setReportUserDialogOpen(true)}
                />
              </Grid>
            )}
          </Grid>
        }
      >
        <Grid container direction={'column'} className={classes.messagesWrapper} spacing={1}>
          <Grid item xs={12}>
            {conversation && displayMessages}
            <div id={'lastMessageRefId'}></div>
          </Grid>
        </Grid>
      </MenuDrawer>
      <ConfirmationDialog
        titleText={t('businessCardsPage.businessCard.reportuser.title')}
        contentText={t('businessCardsPage.businessCard.reportuser.message')}
        cancelText={t('commonButtons.cancel')}
        confirmText={t('commonButtons.yes')}
        open={reportUserDialogOpen}
        errorMessage={errorMessage}
        successMessage={successMessage}
        disableAction={disableAction}
        onConfirm={reportUser}
        onCancel={onCancelReportDialog}
      />
    </div>
  )
}

export default ChatMessagesContainer
