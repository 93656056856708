import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import MenuList from '@material-ui/core/MenuList'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import UserAvatar from '../UserAvatar/UserAvatar'
import { createHtmlIdString } from '../../Utils/utils'
import { Conversation, User, UserRole, userService } from '../../Services/services-index'
import { startConversationAsync } from '../../Reducers/socketSlice'
import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import {
  triggerMuteAll,
  triggerMuteUser,
  UserActionBody,
} from '../ToolButtonsContainer/ToolButtonSlice'
import {
  ChatMenuItem,
  MenuDrawer,
  MenuHeader,
  SearchBar,
  ShadowedButton,
} from '../../Common/common-index'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import { updateActiveConversation, updateActiveConversationId } from '../Chat/ChatSlice'
import { updateIsUserListPageActive } from './UserListSlice'
import { DrawerPositions } from '../../Common/Components/MenuDrawer/MenuDrawer'
import {
  RecruitingDashboardPages,
  switchRecruitingDashboardPage,
  updateIsRecruitingMobileViewActive,
  updateIsRecruitingDetailsViewActive,
  updateSelectedRecruitingUserId,
} from '../RecruitingDashboard/RecruitingDashboardSlice'
import { IndexPageUpdater } from '../../Components/IndexPageUpdater/IndexPageUpdater'

const userStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.paper,
      minHeight: '100%',
      flexWrap: 'nowrap',
    },
    emptyMessage: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      top: '50%',
      width: '100%',
    },
    avatar: {
      border: '3px solid white',
      width: '50px',
      height: '50px',
    },
    participantWrapper: {
      height: '90px',
      '& div[class*="MuiListItem-root"]': {
        alignItems: 'center',
        display: 'flex',
        padding: '0 24px',
        height: '90px',
        width: '100%',
      },
    },
    button: {
      alignItems: 'center',
      cursor: 'pointer',
      color: theme.palette.primary.main,
      display: 'flex',
      textDecoration: 'underline',
      justifyContent: 'center',
      position: 'absolute',
      width: '100%',
    },
    recruitingDashboard: {
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'column',
    },
    headerWrapper: {
      alignItems: 'center',
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      minHeight: '80px',
      padding: '0 24px',
    },
    refreshButtonWrapper: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: '400px',
      },
    },
    refreshButton: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 3),
    },
    errorStatus: {
      color: '#b71c1c',
      display: 'flex',
      fontSize: '14px',
      paddingTop: theme.spacing(4),
    },
    searchBar: {
      alignItems: 'center',
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      padding: theme.spacing(0, 3),
      width: 'calc(100% - 48px)',
    },
    searchInput: {
      fontFamily: 'Graphik-Regular',
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '20px',
      width: '100%',
      height: '40px',
      backgroundColor: theme.palette.background.default,
    },
    searchFocusIcon: {
      '& svg': {
        color: theme.palette.primary.main,
      },
    },
    defaultSearchIcon: {
      '& svg': {
        color: theme.palette.grey[100],
      },
    },
    fixTop: {
      position: 'fixed',
      top: '0',
      width: '400px',
      zIndex: 1000,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    flexiContent: {
      position: 'absolute',
      top: '240px',
      overflowX: 'hidden',
      overflowY: 'auto',
      width: '400px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    emptyList: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      height: '100%',
    },
    subtitle: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: theme.palette.background.paper,
      paddingTop: '6px',
      paddingBottom: '20px',
    },
  })
)

export interface Props {
  isRecruitingDashboardUserList?: boolean
}

const UserListContainer: FC<Props> = ({ isRecruitingDashboardUserList }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const classes = userStyles()

  const currentUser = useAppSelector((state) => state.auth.currentUser)
  const originalUserList = useAppSelector((state) => state.user.userList)
  const activeUserList = useAppSelector((state) => state.toolButtons.userList)
  const voiceRegion = useAppSelector((state) => state.toolButtons.voiceRegion)
  const selectedPreferences = useAppSelector((state) => state.preferences.selectedPreferences)
  const isRecruitingMobileViewActive = useAppSelector(
    (state) => state.recruiting.isRecruitingMobileViewActive
  )
  const hasAccessToMuteAll = useAppSelector((state) => state.toolButtons.muteAllAllowed)

  const [hasUsers, setHasUsers] = useState<boolean>(false)
  const [displayUserList, setDisplayUserList] = useState<any[]>(activeUserList)
  const [activeUsersReady, setActiveUsersReady] = useState<any[]>()

  const [errorMessage, setErrorMessage] = useState<string>('')
  const [searchTextInput, setSearchTextInput] = useState<string>('')
  const [noSearchResult, setNoSearchResult] = useState<boolean>(false)
  const [searchedUsers, setSearchedUsers] = useState<User[]>([])
  const [recrutingUserList, setRecrutingUserList] = useState<User[]>([])
  const [hasEmptyListMessage, setHasEmptyListMessage] = useState<boolean>(false)
  const [canMuteIndividualUsers, setCanMuteIndividualUsers] = useState<boolean>(false)

  const showCompany = selectedPreferences?.show_company?.value === 'yes' ? true : false
  const showJobTitle = selectedPreferences?.show_job_title?.value === 'yes' ? true : false

  useEffect(() => {
    if (activeUserList) {
      setDisplayUserList(activeUserList)
    }
  }, [activeUserList])

  useEffect(() => {
    dispatch(updateIsSideMenuOpen(true))
    dispatch(updateIsUserListPageActive(true))
    if (!recrutingUserList.length && currentUser) {
      fetchLoggedInUserList()
    }
    const hasAdminRights = !!isAdminOrEventAdmin()
    setCanMuteIndividualUsers(hasAdminRights)
  }, [])

  useEffect(() => {
    checkHasUsers()
    renderActiveUsers()
  }, [displayUserList, activeUserList])

  useEffect(() => {
    renderActiveUsers()
  }, [hasUsers])

  useEffect(() => {
    if (recrutingUserList?.length) {
      renderRecruitingUsers()
    }
  }, [recrutingUserList])

  const isAdminOrEventAdmin = () => {
    return currentUser?.roles?.some((role) => role.role === 'event-admin' || role.role === 'admin')
  }

  const fetchLoggedInUserList = () => {
    if (currentUser) {
      userService
        .getLoggedUsersInEvent(currentUser.event_code, UserRole.Visitor)
        .then((data: any) => {
          if (data?.status === 200) {
            const activeUserList = data?.data?.users
            const displayUserList = activeUserList.filter((u: User) => u.id !== currentUser?.id)
            !displayUserList?.length ? setHasEmptyListMessage(true) : setHasEmptyListMessage(false)
            setRecrutingUserList(displayUserList)
            setErrorMessage('')
          }
        })
        .catch((error) => {
          console.error('An error occurred:', error.message)
          const errorText = 'An unexpected error occurred. Please try again later.'
          setErrorMessage(errorText)
        })
    }
  }

  const checkHasUsers = () => {
    const userList = Object.values(displayUserList)
    if (userList?.length) {
      userList.forEach((user: UserActionBody) => {
        if (user?.externalUserId !== undefined && Number(user.externalUserId) !== currentUser?.id) {
          setHasUsers(true)
        }
      })
    } else {
      setHasUsers(false)
    }
  }

  // To formate user details
  const formatUserDescription = (company: string | undefined, jobTitle: string | undefined) => {
    let description = ''
    if (showCompany && company) {
      description += company
    }
    if (showJobTitle && jobTitle) {
      if (description.length > 0) {
        description += ' — '
      }
      description += jobTitle
    }
    return description
  }

  // Open selected user chat conversation
  const openUserConversation = (userId: number) => {
    if (
      selectedPreferences &&
      selectedPreferences['private_chat'] &&
      selectedPreferences['private_chat'].value === 'no'
    ) {
      return
    }
    console.log('run: openUserConversation')
    if (userId !== undefined || userId !== null) {
      if (!isRecruitingDashboardUserList) {
        console.log('Open User Conversation: ', [userId])
        dispatch(switchMenuPage(MenuPages.Chat))
        dispatch(startConversationAsync({ receiverIds: [userId] }))
        reSetConversation()
      }
      if (isRecruitingDashboardUserList) {
        dispatch(updateIsRecruitingDetailsViewActive(true))
        dispatch(updateSelectedRecruitingUserId(userId))
        reSetConversation()
      }
      if (isRecruitingDashboardUserList && isRecruitingMobileViewActive) {
        dispatch(switchMenuPage(MenuPages.RecruitingDashboard))
        dispatch(
          switchRecruitingDashboardPage(RecruitingDashboardPages.RecruitingDashboardProfilePage)
        )
      }
    }
  }

  const renderRecruitingDashboardContent = () => {
    return (
      <div className={classes.recruitingDashboard}>
        <div className={classes.fixTop}>
          <div className={classes.headerWrapper}>
            <Typography variant={'h4'} id={'dashboard-header'}>
              {t('recruiting.userListPage.headerTitle')}
            </Typography>
          </div>
          <div className={classes.refreshButton}>
            <ShadowedButton
              id='recruiting-dashboard-refresh-button'
              text={t('recruiting.userListPage.refresh')}
              buttonStyle={'default'}
              wrapperClasses={classes.refreshButtonWrapper}
              onClick={fetchLoggedInUserList}
            ></ShadowedButton>
            {errorMessage && (
              <Typography variant='body2' className={classes.errorStatus}>
                {errorMessage}
              </Typography>
            )}
          </div>
          <div className={classes.searchBar}>
            <SearchBar
              id='recruiting-dshboard-user-list-search'
              placeholderText={t('recruiting.userListPage.search.placeHolder')}
              hideAutoFill={true}
              searchTextInput={searchTextInput}
              onChange={(event) => filterUserList(event as ChangeEvent<HTMLInputElement>)}
            />
          </div>
          {noSearchResult && (
            <div className={classes.emptyList}>
              <Typography>{t('recruiting.userListPage.search.noUser')}</Typography>
            </div>
          )}
        </div>
        {!noSearchResult && !hasEmptyListMessage && (
          <div className={classes.flexiContent}>{renderRecruitingUsers()}</div>
        )}
        {hasEmptyListMessage && emptyUserList()}
      </div>
    )
  }

  const filterUserList = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchedUsers([])
    let results: any[] = []
    const searchInput = event?.target?.value
    if (searchInput === '' || searchInput === ' ' || !event) {
      setSearchTextInput('')
      setNoSearchResult(false)
      return
    } else if (!!searchInput.length) {
      const searchValue = searchInput
      setSearchTextInput(searchValue)
      results = [...recrutingUserList].filter((user) => {
        const searchableText = `
          ${user.firstname} ${user.lastname}
          ${showCompany ? user.company : ''} ${showJobTitle ? user.job_title : ''}
          ${user.username}
        `.toLowerCase()
        return searchableText.includes(searchValue.toLowerCase())
      })

      results && results.length ? setNoSearchResult(false) : setNoSearchResult(true)
    }
    setSearchedUsers(results)
  }

  const renderRecruitingUsers = () => {
    let newUserList: any
    if (!searchTextInput?.length) {
      if (!recrutingUserList) {
        return null
      } else {
        newUserList = recrutingUserList
      }
    } else {
      if (!searchedUsers) {
        return null
      } else {
        newUserList = searchedUsers
      }
    }
    return newUserList
      .sort((a: any, b: any) => {
        return a.firstname > b.firstname ? 1 : -1
      })
      .map((user: User) => {
        return renderUsers(user)
      })
  }

  const muteUser = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    nid: number | undefined,
    isMuted: boolean | undefined
  ) => {
    e.stopPropagation()
    if (!nid) {
      console.error('nid of user undefined')
      return
    }
    if (!isMuted) {
      dispatch(triggerMuteUser(nid))
    }
  }

  const renderUsers = (
    displayUser: User,
    isMuted?: boolean | undefined,
    nid?: number | undefined
  ) => {
    if (displayUser) {
      const title = `${displayUser.firstname} ${displayUser.lastname}`
      const description = formatUserDescription(displayUser?.company, displayUser?.job_title)

      return (
        <>
          <div className={classes.participantWrapper}>
            <MenuList disablePadding>
              <ChatMenuItem
                id={createHtmlIdString('user-list-menu-item-', displayUser?.firstname)}
                key={displayUser.id}
                nid={nid}
                title={title}
                showMic={!isRecruitingDashboardUserList ? true : false}
                description={description}
                isMuted={isMuted}
                onClick={() => openUserConversation(displayUser?.id as number)}
                onMuteUser={
                  canMuteIndividualUsers
                    ? (e, nid, isMuted) => muteUser(e, nid, isMuted)
                    : undefined
                }
                canMuteIndividualUsers={canMuteIndividualUsers}
                avatar={
                  <UserAvatar
                    userId={displayUser.id as number}
                    hasUnreadMessage={false}
                    width={50}
                    height={50}
                    tooltipPlacement='bottom'
                  />
                }
              />
            </MenuList>
          </div>
        </>
      )
    }
    return
  }

  // Get User Summary details
  const getUserData = async (userId: number) => {
    if (userId) {
      const filteredUser = originalUserList[userId]
      if (filteredUser !== undefined) {
        return filteredUser
      }
      if (filteredUser === undefined) {
        try {
          const res = await userService.getUserSummary(userId)
          if (res?.status === 200 && res?.data) {
            return res.data
          }
          return
        } catch (error: any) {
          console.log(error)
        }
      }
    }
    return
  }

  // To display active user list
  const renderActiveUsers = async () => {
    const activeUsersJSX = await Promise.all(
      Object.values(displayUserList)
        .sort((a: any, b: any) => {
          return a.userName.toLowerCase() > b.userName.toLowerCase() ? 1 : -1
        })
        .map(async (participant: UserActionBody) => {
          if (
            !participant ||
            !participant?.externalUserId ||
            participant?.externalUserId === undefined ||
            Number(participant?.externalUserId) === currentUser?.id
          ) {
            return
          } else {
            const displayUser: User | undefined = await getUserData(
              Number(participant?.externalUserId)
            )
            if (displayUser === undefined) {
              return
            }
            if (
              participant &&
              displayUser &&
              displayUser.id === Number(participant.externalUserId)
            ) {
              return renderUsers(displayUser, participant?.muted, participant?.nid)
            }
            return null
          }
        })
    )
    if (activeUsersJSX.length) {
      setActiveUsersReady(activeUsersJSX)
    }
  }

  // To Mute all participants
  const onMuteAll = () => {
    dispatch(triggerMuteAll())
  }

  // To reset conversation state
  const reSetConversation = () => {
    dispatch(updateActiveConversationId(''))
    dispatch(updateActiveConversation({} as Conversation))
  }

  const emptyUserList = () => {
    return (
      <div className={classes.emptyMessage}>
        <Typography>{t('userList.emptylist')}</Typography>
      </div>
    )
  }

  // User Header Actions
  const OnCloseClick = () => {
    reSetConversation()
    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(updateIsUserListPageActive(false))
    dispatch(updateIsRecruitingMobileViewActive(false))
  }

  const OnGoBack = () => {
    reSetConversation()
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(toggleMenu(true))
    dispatch(updateIsUserListPageActive(false))
  }

  return (
    <>
      <IndexPageUpdater />
      <MenuDrawer
        isOpen={true}
        drawerPosition={
          isRecruitingDashboardUserList ? DrawerPositions.LEFT : DrawerPositions.RIGHT
        }
        drawerHeader={
          !isRecruitingDashboardUserList ||
          (isRecruitingDashboardUserList && isRecruitingMobileViewActive) ? (
            <MenuHeader
              id={'user-list'}
              title={
                isRecruitingDashboardUserList && isRecruitingMobileViewActive
                  ? t('recruiting.userListPage.headerTitle')
                  : t('userList.title')
              }
              hasDivider={
                isRecruitingDashboardUserList && isRecruitingMobileViewActive ? true : false
              }
              onGoBack={OnGoBack}
              onClose={OnCloseClick}
            />
          ) : null
        }
        drawerFooter={
          !isRecruitingDashboardUserList ? (
            <>
              {hasUsers && hasAccessToMuteAll && (
                <Typography
                  id={'mute-all-participants-button'}
                  variant='body1'
                  className={classes.button}
                  onClick={onMuteAll}
                >
                  {t('userList.muteAll')}
                </Typography>
              )}
            </>
          ) : null
        }
      >
        <div className={classes.container}>
          {!isRecruitingDashboardUserList && (
            <div className={classes.subtitle}>
              <Typography variant={'subtitle1'} id={'voice-region-header'}>
                {voiceRegion}
              </Typography>
            </div>
          )}
          {isRecruitingDashboardUserList && renderRecruitingDashboardContent()}
          {!isRecruitingDashboardUserList && hasUsers && <>{activeUsersReady}</>}
          {!isRecruitingDashboardUserList && !hasUsers && emptyUserList()}
        </div>
      </MenuDrawer>
    </>
  )
}

export default UserListContainer
