import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Typography from '@material-ui/core/Typography'
import { Slide } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { useAppDispatch, useAppSelector } from '../../Reducers/hooks'
import {
  ChatPages,
  switchChatPage,
  updateActiveConversation,
  updateActiveConversationId,
  updateAddNewUsersIsActive,
  updateGroupConversaiton,
} from './ChatSlice'
import { MenuPages, switchMenuPage, toggleMenu, updateIsSideMenuOpen } from '../Menu/MenuSlice'
import UserAvatar from '../UserAvatar/UserAvatar'
import { startConversationAsync } from '../../Reducers/socketSlice'
import { updateIsRoomConversation } from '../RoomChat/RoomChatSlice'
import { ChatMenuItem, SearchBar, MenuDrawer, MenuHeader } from '../../Common/common-index'
import { User, userService, Conversation } from '../../Services/services-index'
import { createHtmlIdString } from '../../Utils/utils'
import { formatUserItemDescription } from '../../Utils/chat.utils'

const userStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      marginLeft: '10%',
      marginRight: '10%',
    },
    container: {
      flexWrap: 'nowrap',
      marginTop: '95px',
    },
    searchBar: {
      padding: theme.spacing(0, 3),
      width: 'calc(100% - 48px)',
      position: 'absolute',
      top: '72px',
      left: '0',
      height: '80px',
      zIndex: 1000,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.background.paper,
    },
    chatList: {
      flexGrow: 1,
      flexBasis: 0,
    },
    availableUsers: {
      padding: theme.spacing(2, 4),
    },
    wrapper: {
      padding: theme.spacing(0, 1),
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
    },
    text: {
      width: '80%',
      marginTop: theme.spacing(2),
    },
    textCenter: {
      textAlign: 'center',
      margin: theme.spacing(2, 0),
    },
    selectUser: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      height: '100%',
      margin: theme.spacing(2, 0),
      width: '100%',
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 6,
      paddingBottom: 30,
      flexBasis: 0,
    },
    button: {
      textDecoration: 'underline',
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
    noUserSelected: {
      cursor: 'default',
      color: theme.palette.grey[200],
      fontWeight: 'normal',
      fontSize: '18px',
      fontFamily: 'Graphik-Regular',
      pointerEvents: 'none',
    },
    selectedUser: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& div[class*="MuiGrid-item"]': {
        height: '90px',
      },
    },
    selectedUserWrapper: {
      height: '90px',
      '& div[class*="MuiListItem-root"]': {
        width: '100%',
        padding: '0 24px',
        display: 'flex',
        alignItems: 'center',
      },
    },
    listItem: {
      display: 'flex',
      justifyContent: 'space-evenly',
    },
    avatarContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    textContainer: {
      marginLeft: theme.spacing(2),
    },
    list: {
      width: '100%',
    },
    gridFlex: {
      display: 'flex',
      padding: '40px 24px 40px 0',
      flexDirection: 'row-reverse',
    },
    errorStatus: {
      margin: theme.spacing(2, 0, 3, 0),
      fontSize: '14px',
      color: '#b71c1c',
      display: 'flex',
      justifyContent: 'center',
    },
  })
)

const ChatNewConversationContainer: FC = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const classes = userStyles()

  const currentUser = useAppSelector((state) => state.auth.currentUser)
  const activeConversation = useAppSelector((state) => state.chat.activeConversation)
  const addNewUsersIsActive = useAppSelector((state) => state.chat.addNewUsersIsActive)
  const selectedPreferences = useAppSelector((state) => state.preferences.specificPreferences)

  const [selectedUsers, setSelectedUsers] = useState<User[]>([])
  const [availableUsers, setAvailableUsers] = useState<User[]>([])
  const [searchedUsers, setSearchedUsers] = useState<User[]>([])
  const [noResultFound, setNoResultFound] = useState<boolean | null>(null)
  const [searchTextInput, setSearchTextInput] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [showCompany, setShowCompany] = useState<boolean>(false)
  const [showJobTitle, setShowJobTitle] = useState<boolean>(false)

  useEffect(() => {
    if (!availableUsers.length && currentUser) {
      userService
        .getLoggedUsersInEvent(currentUser.event_code)
        .then((data: any) => {
          if (data?.status === 200) {
            const activeUserList = data?.data?.users
            const displayUserList = activeUserList.filter((u: User) => u.id !== currentUser?.id)
            setAvailableUsers(displayUserList.slice(0, 50))
          }
        })
        .catch((error) => {
          console.error('An error occurred:', error.message)
          const errorText = 'An unexpected error occurred. Please try again later.'
          setErrorMessage(errorText)
        })
    }
  }, [])

  useEffect(() => {
    if (selectedPreferences) {
      const showCompanyPreference = selectedPreferences['show_company']
      const showJobTitlePreference = selectedPreferences['show_job_title']
      showCompanyPreference && showCompanyPreference?.value === 'yes'
        ? setShowCompany(true)
        : setShowCompany(false)
      showJobTitlePreference && showJobTitlePreference?.value === 'yes'
        ? setShowJobTitle(true)
        : setShowJobTitle(false)
    }
  }, [selectedPreferences])

  useEffect(() => {}, [showCompany, showJobTitle])

  useEffect(() => {
    dispatch(updateIsSideMenuOpen(true))
    dispatch(updateIsRoomConversation(false))
  }, [])

  useEffect(() => {
    if (addNewUsersIsActive && activeConversation) {
      const { participants } = activeConversation
      console.log('addNewUsersIsActive', participants, 'activeConversation', activeConversation)
      const existingusers = participants.filter((u) => u.id !== currentUser?.id)
      setSelectedUsers(existingusers)
    }
  }, [addNewUsersIsActive])

  // To add/start a new user conversation
  const handleAdd = (newUser: User) => {
    const checkUserExists = selectedUsers.some((u) => u.id === newUser.id)
    if (!checkUserExists) {
      const newSelectedUsers = [newUser, ...selectedUsers]
      setSelectedUsers(newSelectedUsers)

      //Update available users list
      const newAvailableUserList = availableUsers.filter((u) => u.id !== newUser.id)
      setAvailableUsers(newAvailableUserList)
      setSearchedUsers([])
      setNoResultFound(null)
    } else {
      setErrorMessage(t('chatPage.chatNewConversation.userExists'))

      // Turn of error message
      setTimeout(() => {
        setErrorMessage('')
      }, 2500)
    }
  }

  // To Delete a selected user from the selected user list
  const handleDelete = (user: User) => {
    const newSelectedUsers = selectedUsers.filter((u) => u.id !== user.id)
    setSelectedUsers(newSelectedUsers)

    //Update available users list
    const filteredUsers = availableUsers.filter((u: User) => {
      return u.id !== user.id
    })
    const newAvailableUserList = [user, ...filteredUsers]
    setAvailableUsers(newAvailableUserList)
    if (!selectedUsers.length) {
      setSearchTextInput('')
    }
  }

  // To Start a conversation with selected user(s)
  const handleStartConversation = () => {
    const receiverIds: number[] = []
    selectedUsers.forEach((u) => receiverIds.push(u.id as number))
    console.log('handleStart', receiverIds)
    receiverIds?.length > 1
      ? dispatch(updateGroupConversaiton({ isGroup: true, count: receiverIds?.length + 1 }))
      : dispatch(updateGroupConversaiton({ isGroup: false, count: receiverIds?.length + 1 }))

    dispatch(updateActiveConversationId(''))
    dispatch(updateActiveConversation({} as Conversation))
    dispatch(startConversationAsync({ receiverIds }))
  }

  const renderUserItem = (users: User[]) => {
    let filteredUser: User[] = []
    if (selectedUsers?.length && users) {
      const newUserList = availableUsers.filter((user: User) => {
        return !selectedUsers.find((u: User) => {
          return u.id === user.id
        })
      })
      filteredUser = newUserList
    } else {
      filteredUser = users
    }

    if (filteredUser) {
      return filteredUser.map((user) => {
        const { id, firstname, lastname, company, job_title } = user
        const menuItemTitle = firstname + ' ' + lastname
        const description = formatUserItemDescription(showCompany, showJobTitle, company, job_title)

        return (
          <div className={classes.selectedUserWrapper} key={id}>
            <ChatMenuItem
              id={createHtmlIdString('new-conversation-user-item-', menuItemTitle)}
              key={id}
              title={menuItemTitle}
              description={description}
              onClick={() => handleAdd(user)}
              avatar={
                <UserAvatar
                  userId={id as number}
                  hasUnreadMessage={false}
                  width={50}
                  height={50}
                  tooltipPlacement='bottom'
                />
              }
            />
          </div>
        )
      })
    }
    return
  }

  const renderSelectedUsers = () => {
    return selectedUsers.map((user) => {
      const { id, firstname, lastname, company, job_title } = user
      const menuItemTitle = firstname + ' ' + lastname
      const description = formatUserItemDescription(showCompany, showJobTitle, company, job_title)

      return (
        <div className={classes.selectedUserWrapper} key={id}>
          <ChatMenuItem
            id={createHtmlIdString('new-conversation-selected-user-item-', menuItemTitle)}
            key={id}
            title={menuItemTitle}
            description={description}
            avatar={<UserAvatar userId={id as number} width={50} height={50} />}
            onClose={() => handleDelete(user)}
          />
        </div>
      )
    })
  }

  const searchUsers = (search: ChangeEvent<HTMLInputElement>) => {
    setSearchedUsers([])
    let results: any[] = []
    if (search.target.value === '' || search.target.value === ' ' || !search) {
      setSearchTextInput('')
      setNoResultFound(false)
      return
    } else if (!!search.target.value.length) {
      const searchValue = search.target.value
      setSearchTextInput(searchValue)
      results = [...availableUsers].filter((user) => {
        const searchableText = `
          ${user.firstname} ${user.lastname}
          ${showCompany ? user.company : ''} ${showJobTitle ? user.job_title : ''}
        `.toLowerCase()
        return searchableText.includes(searchValue.toLowerCase()) && !selectedUsers.includes(user)
      })

      results && results.length ? setNoResultFound(false) : setNoResultFound(true)
    }
    setSearchedUsers(results)
  }

  const renderNewConversationContent = () => {
    return (
      <Slide direction='left' in={true} mountOnEnter unmountOnExit>
        <div>
          {searchTextInput && (searchedUsers?.length || noResultFound) && (
            <Divider className={classes.divider} />
          )}
          {noResultFound && !searchedUsers.length && (
            <Typography className={classes.textCenter} variant={'body2'}>
              {t('chatPage.chatNewConversation.noSearchResult')}
            </Typography>
          )}
          {!selectedUsers.length && (
            <Typography id='select-one-person' className={classes.selectUser} variant={'body2'}>
              {t('chatPage.chatNewConversation.selectAPerson')}
            </Typography>
          )}
          {!!searchedUsers.length && renderUserItem(searchedUsers)}
          <div className={classes.selectedUser}>
            {renderSelectedUsers()}
            {selectedUsers && errorMessage && onError()}
          </div>
          <div className={classes.gridFlex}>
            <Typography
              variant='body1'
              onClick={() => handleStartConversation()}
              className={
                selectedUsers.length
                  ? classes.button
                  : `${classes.noUserSelected} ${classes.button}`
              }
            >
              {t('chatPage.chatNewConversation.startConversation')}
            </Typography>
          </div>
          <div className={classes.chatList}>
            <Typography variant={'h5'} className={classes.availableUsers}>
              {!!availableUsers.length
                ? t('chatPage.chatNewConversation.currently') +
                  availableUsers.length +
                  (availableUsers.length > 1
                    ? t('chatPage.chatNewConversation.peopleInEvent')
                    : t('chatPage.chatNewConversation.personInEvent'))
                : t('chatPage.chatNewConversation.noUsers')}
            </Typography>
            {renderUserItem(availableUsers)}
            {!selectedUsers && errorMessage && onError()}
          </div>
        </div>
      </Slide>
    )
  }

  // On Error message appears
  const onError = () => {
    return (
      <>
        <Typography variant='body2' className={classes.errorStatus}>
          {errorMessage}
        </Typography>
      </>
    )
  }

  const goBackToChatScreens = () => {
    if (selectedUsers?.length && addNewUsersIsActive) {
      handleStartConversation()
    } else if ((!selectedUsers?.length && addNewUsersIsActive) || !addNewUsersIsActive) {
      onReset()
      dispatch(switchChatPage(ChatPages.ChatList))
    }
  }

  // Header Actions
  const OnGoBack = () => {
    goBackToChatScreens()
    onReset()
  }

  const closeMenu = () => {
    dispatch(switchMenuPage(MenuPages.Menu))
    dispatch(toggleMenu(false))
    dispatch(updateIsSideMenuOpen(false))
    dispatch(switchChatPage(ChatPages.ChatList))
    onReset()
  }

  // Footer Actions
  const onCancel = () => {
    goBackToChatScreens()
    onReset()
  }

  // To reset the filters
  const onReset = () => {
    dispatch(updateAddNewUsersIsActive(false))
    setSelectedUsers([])
    setSearchedUsers([])
    setNoResultFound(null)
    dispatch(updateActiveConversation({} as Conversation))
    dispatch(updateActiveConversationId(''))
  }

  return (
    <MenuDrawer
      isOpen={true}
      drawerHeader={
        <MenuHeader
          title={t('chatPage.chatNewConversation.newConversation')}
          hasDivider={true}
          onGoBack={OnGoBack}
          onClose={closeMenu}
        />
      }
      drawerFooter={
        <Typography
          id='cancel-button'
          variant='body1'
          onClick={onCancel}
          className={classes.button}
        >
          {t('chatPage.chatNewConversation.cancel')}
        </Typography>
      }
    >
      <Divider className={classes.divider} />
      <Grid className={classes.container} container direction='column' justifyContent='center'>
        <div className={classes.searchBar}>
          <SearchBar
            id='new-conversation-search-bar'
            placeholderText={t('chatPage.chatNewConversation.searchBar.placeholder')}
            searchTextInput={searchTextInput}
            onChange={(e) => searchUsers(e as ChangeEvent<HTMLInputElement>)}
          />
        </div>
        {renderNewConversationContent()}
      </Grid>
    </MenuDrawer>
  )
}

export default ChatNewConversationContainer
